@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/* Required */
body {
  overflow: hidden;
  color: #000;
}

.layout {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
}

.header {
  border-bottom: 1px solid lightgrey;
}

.main {
  overflow-y: scroll;
}

.footer {
  border-top: 1px solid lightgrey;
}

.App-intro {
  font-size: large;
}

a {
  color: #FFF;
}
a:hover {
   color: #99F
}