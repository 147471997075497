.flexcontainer {
  height: 40vh;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row {
  width: auto;
}
.flexitem {
  width: auto;
  line-height: 20px;
  color: white;
  text-align: center;
}